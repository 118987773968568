import React from 'react'
import './Options.css'

import { Link } from 'react-router-dom'
import ignouLogo from './../images/ignou-logo.png'

export default function Options() {
    return (
        <div className='hide-extra-data'>

            {/* Stage-I (Admission) */}


            <h2 className="my-4">Stage - I (Admission)</h2>

            <div className='row mx-2'>


                <a class="col-4 btn" onClick={() => { showWebContent("http://ignou.ac.in/ignou/studentzone/downloads/3") }}>
                    <div>
                        <img className='' width="56" height="56" src="https://img.icons8.com/external-goofy-flat-kerismaker/96/external-Brochure-graphic-design-goofy-flat-kerismaker.png" alt="push-notifications" />
                        <br />
                        <b>
                            Common Prospectus
                        </b>
                    </div>
                </a>

                <a class="col-4 btn" onClick={() => { showWebContent("https://ignouadmission.samarth.edu.in/") }}>
                    <div>
                        <img className='' width="56" height="56" src={ignouLogo} alt="push-notifications" />
                        <br />
                        <b>
                            Admission
                        </b>
                    </div>
                </a>


                <a class="col-4 btn" onClick={() => { showWebContent("https://isms.ignou.ac.in/changeadmdata/AdmissionStatusNew.ASP") }}>
                    <div>
                        <img className='' width="56" height="56" src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/64/external-status-computer-programming-icons-flaticons-lineal-color-flat-icons.png" alt="push-notifications" />
                        <br />
                        <b>
                            Admission Details
                        </b>
                    </div>
                </a>



            </div>


            <div className='row mx-2'>


                <a class="col-4 btn" onClick={() => { showWebContent("https://ignouadmission.samarth.edu.in/index.php/site/archive") }}>
                    <div>
                        <img className='' width="56" height="56" src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/64/external-student-card-online-education-flaticons-lineal-color-flat-icons.png" alt="push-notifications" />
                        <br />
                        <b>
                            ID Card
                        </b>
                    </div>
                </a>

                <a class="col-4 btn" onClick={() => { showWebContent("http://ignou.ac.in/ignou/studentzone/results/1") }}>
                    <div>
                        <img className='' width="56" height="56" src="https://img.icons8.com/stickers/100/door-sensor-error.png" alt="push-notifications" />
                        <br />
                        <b>
                            Entrance Result
                        </b>
                    </div>
                </a>

                <a class="col-4 btn" onClick={() => { showWebContent("http://ignou.ac.in/ignou/studentzone/results/8") }}>
                    <div>
                        <img className='' width="56" height="56" src="https://img.icons8.com/color-glass/96/mba.png" alt="push-notifications" />
                        <br />
                        <b>
                            OPENMAT Entrance
                        </b>
                    </div>
                </a>




            </div>









            {/* Stage-II (Study) */}


            <hr className="mt-5" />

            <h2 className="my-4">Stage - 2 (Study) </h2>

            <div className='row mx-2'>


                <a class="col-4 btn" onClick={() => { showWebContent("https://webservices.ignou.ac.in/assignments/") }}>
                    <div>
                        <img className='' width="56" height="56" src="https://img.icons8.com/ios-filled/50/FA5252/todo-list.png" alt="push-notifications" />
                        <br />
                        <b>
                            Assignments
                        </b>
                    </div>
                </a>

                <Link class="col-4 btn" to="/store">
                    <div>
                        <img className='' width="56" height="56" src="https://img.icons8.com/ios-filled/50/94D82D/todo-list.png" alt="push-notifications" />
                        <br />
                        <b>
                            Solved Assignments
                        </b>
                    </div>
                </Link>


                <a class="col-4 btn" onClick={() => { showWebContent("https://egyankosh.ac.in/") }}>
                    <div>
                        <img className='' width="56" height="56" src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/64/external-library-online-education-flaticons-lineal-color-flat-icons.png" alt="push-notifications" />
                        <br />
                        <b>
                            e-Gyan Kosh
                        </b>
                    </div>
                </a>




            </div>











            {/* Stage-III (Exams) */}

            <hr className="mt-5" />

            <h2 className="my-4">Stage - 3 (Exams) </h2>

            <div className='row mx-2'>


                <a class="col-4 btn" onClick={() => { showWebContent("https://exam.ignou.ac.in/") }}>
                    <div>
                        <img className='' width="56" height="56" src="https://img.icons8.com/papercut/60/survey.png" alt="push-notifications" />
                        <br />
                        <b>
                            Online Exam Forms
                        </b>
                    </div>
                </a>


                <a class="col-4 btn" onClick={() => { showWebContent("https://webservices.ignou.ac.in/Pre-Question/") }}>
                    <div>
                        <img className='' width="56" height="56" src="https://img.icons8.com/ios-filled/50/FA5252/quiz.png" alt="push-notifications" />
                        <br />
                        <b>
                            Question Papers
                        </b>
                    </div>
                </a>


                <Link class="col-4 btn" to="/store">
                    <div>
                        <img className='' width="56" height="56" src="https://img.icons8.com/ios-filled/50/20C997/quiz.png" alt="push-notifications" />
                        <br />
                        <b>
                            Solved Papers
                        </b>
                    </div>
                </Link>




            </div>

            <div className='row mx-2'>

                <a class="col-4 btn" onClick={() => { showWebContent("http://ignou.ac.in/ignou/aboutignou/division/sed/datesheet") }}>
                    <div>
                        <img className='' width="56" height="56" src="https://img.icons8.com/fluency/96/overtime--v1.png" alt="push-notifications" />
                        <br />
                        <b>
                            DateSheet
                        </b>
                    </div>
                </a>

                <a class="col-4 btn" onClick={() => { showWebContent("http://www.ignou.ac.in/ignou/studentzone/results/6") }}>
                    <div>
                        <img className='' width="56" height="56" src="https://img.icons8.com/officel/80/id-verified.png" alt="push-notifications" />
                        <br />
                        <b>
                            Admit Card
                        </b>
                    </div>
                </a>


                <a class="col-4 btn" onClick={() => { showWebContent("https://onlineservices.ignou.ac.in/reevaluation/") }}>
                    <div>
                        <img className='' width="56" height="56" src="https://img.icons8.com/external-others-pike-picture/100/external-Recheck-expert-others-pike-picture.png" alt="push-notifications" />
                        <br />
                        <b>
                            Re-Evaluation Form
                        </b>
                    </div>
                </a>




            </div>







            {/* Stage-IV (Results) */}


            <hr className="mt-5" />

            <h2 className="my-4">Stage - 4 (Results) </h2>

            <div className='row mx-2'>

                <a class="col-4 btn" onClick={() => { showWebContent("http://ignou.ac.in/ignou/studentzone/results/2") }}>
                    <div>
                        <img className='' width="56" height="56" src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/64/external-result-social-media-agency-flaticons-lineal-color-flat-icons-3.png" alt="push-notifications" />
                        <br />
                        <b>
                            Term-End Results
                        </b>
                    </div>
                </a>

                <a class="col-4 btn" onClick={() => { showWebContent("http://ignou.ac.in/ignou/studentzone/results/5") }}>
                    <div>
                        <img className='' width="56" height="56" src="https://img.icons8.com/external-others-pike-picture/100/external-Recheck-expert-others-pike-picture.png" alt="push-notifications" />
                        <br />
                        <b>
                            Re-Evaluation Result
                        </b>
                    </div>
                </a>

                <a class="col-4 btn" onClick={() => { showWebContent("https://gradecard.ignou.ac.in/gradecard/") }}>
                    <div>
                        <img className='' width="56" height="56" src="https://img.icons8.com/external-sbts2018-flat-sbts2018/58/external-score-basic-ui-elements-2.3-sbts2018-flat-sbts2018.png" alt="push-notifications" />
                        <br />
                        <b>
                            Score Card
                        </b>
                    </div>
                </a>

            </div>

        </div>
    )
}



function showWebContent(url) {
    if (url != null) {
        window.Android.showWebContent(url)
    }
}

function openNotification() {
    window.Android.openNotification();
}

function openWhatsApp() {
    window.Android.openWhatsApp();
}