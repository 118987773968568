import React from 'react'
import './BottomBar.css'
import { Link } from 'react-router-dom'


export default function BottomBar(props) {
    return (
        <footer>

            <div className='row bottom-menu mx-1'>
                <hr />
                {props.selectHome ?
                    <div class="col-3">

                        <Link to='/' className='text-decoration-none'>
                            <img width="25" height="25" src="https://img.icons8.com/ios-filled/50/2196F3/home--v1.png" alt="home--v1" />
                            <br />
                            Home
                        </Link>

                    </div>
                    :
                    <div class="col-3">

                        <Link to='/' className='text-decoration-none'>
                            <img width="25" height="25" src="https://img.icons8.com/ios/50/2196F3/home--v1.png" alt="home--v1" />
                            <br />
                            Home
                        </Link>

                    </div>
                }


                {props.selectStudy ?

                    <div class="col-3">

                        <Link to='/study' className='text-decoration-none'>
                            <img width="25" height="25" src="https://img.icons8.com/ios-filled/50/2196F3/saving-book.png" alt="saving-book" />
                            <br />
                            Study
                        </Link>

                    </div>

                    :

                    <div class="col-3 text-decoration-none">

                        <Link to='/study' className='text-decoration-none'>
                            <img width="25" height="25" src="https://img.icons8.com/ios/50/2196F3/saving-book.png" alt="saving-book" />
                            <br />
                            Study
                        </Link>

                    </div>

                }

                {props.selectStore ?

                    <div class="col-3">
                        <Link to='/store' className='text-decoration-none'>
                            <img width="25" height="25" src="https://img.icons8.com/ios-filled/50/2196F3/online-store.png" alt="home--v1" />
                            <br />
                            Store
                        </Link>
                    </div>
                    :
                    <div class="col-3">
                        <Link to='/store' className='text-decoration-none'>
                        <img width="25" height="25" src="https://img.icons8.com/ios/50/2196F3/online-store.png" alt="home--v1" />
                        <br />
                        Store
                        </Link>
                    </div>
                }


                {props.selectMore ?
                
                <div class="col-3">
                    <Link to='/more' className='text-decoration-none'>
                    <img width="25" height="25" src="https://img.icons8.com/ios-filled/50/2196F3/brilliant-idea.png" alt="home--v1" />
                    <br />
                    More
                    </Link>
                </div>

                :

                <div class="col-3">
                        <Link to='/more' className='text-decoration-none'>
                        <img width="25" height="25" src="https://img.icons8.com/ios/50/2196F3/brilliant-idea.png" alt="home--v1" />
                        <br />
                        More
                        </Link>
                </div>
            }

                
            </div>



        </footer>
    )
}
