import React from 'react'

import Carousel from 'react-bootstrap/Carousel';

import img1 from '../images/img1.jpg'
import img2 from '../images/img2.jpg'
import img3 from '../images/img3.jpg'


export default function Carousal() {
  return (
    <>
      <Carousel className='slide m-3'>

        <Carousel.Item interval={3000}>
          <img src={img1} className="d-block w-100 border rounded-4" />
        </Carousel.Item>

        <Carousel.Item interval={3000}>
          <img src={img2} className="d-block w-100 border rounded-4"/>
        </Carousel.Item>

        <Carousel.Item interval={3000}>
          <img src={img3} className="d-block w-100 border rounded-4"/>
        </Carousel.Item>
      </Carousel>
    </>







  )
}
