import React from 'react'
import BottomBar from '../component/BottomBar'

import Carousel from '../page-more/Carousal'
import Options from '../page-more/Options'

export default function More() {
  return (
    <div>
      <Carousel />

      <Options />

    <BottomBar selectMore="true" />
    </div>
  )
}
