import React from 'react'
import './Options.css'

import { Link } from 'react-router-dom'


export default function Options() {
    return (
        <div className='hide-extra-data'>

            <div className='row mx-2'>


                <a class="col-4 btn" onClick={()=>{showWebContent("https://webservices.ignou.ac.in/assignments/")}}>
                    <div>
                        <img className='' width="56" height="56" src="https://img.icons8.com/ios-filled/50/FA5252/todo-list.png" alt="push-notifications" />
                        <br />
                        <b>
                            Assignments
                        </b>
                    </div>
                </a>

                <Link class="col-4 btn" to="/store">
                    <div>
                        <img className='' width="56" height="56" src="https://img.icons8.com/ios-filled/50/94D82D/todo-list.png" alt="push-notifications" />
                        <br />
                        <b>
                            Solved Assignments
                        </b>
                    </div>
                </Link>



                <Link class="col-4 btn" to="/store">
                    <div>
                        <img className='' width="56" height="56" src="https://img.icons8.com/external-flaticons-flat-flat-icons/64/external-projects-home-based-business-flaticons-flat-flat-icons.png" alt="push-notifications" />
                        <br />
                        <b>
                            Projects
                        </b>
                    </div>
                </Link>





            </div>


            <div className='row mx-2'>


                <a class="col-4 btn" onClick={()=>{showWebContent("https://webservices.ignou.ac.in/Pre-Question/")}}>
                    <div>
                        <img className='' width="56" height="56" src="https://img.icons8.com/ios-filled/50/FA5252/quiz.png" alt="push-notifications" />
                        <br />
                        <b>
                            Question Papers
                        </b>
                    </div>
                </a>


                <Link class="col-4 btn" to="/store">
                    <div>
                        <img className='' width="56" height="56" src="https://img.icons8.com/ios-filled/50/20C997/quiz.png" alt="push-notifications" />
                        <br />
                        <b>
                            Solved Papers
                        </b>
                    </div>
                </Link>


                <a class="col-4 btn" onClick={()=>{showWebContent("https://egyankosh.ac.in/")}}>
                    <div>
                        <img className='' width="56" height="56" src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/64/external-library-online-education-flaticons-lineal-color-flat-icons.png" alt="push-notifications" />
                        <br />
                        <b>
                            e-Gyan Kosh
                        </b>
                    </div>
                </a>


            </div>


        </div>
    )
}



function showWebContent(url) {
    if (url != null) {
        window.Android.showWebContent(url)
    }
}

function openNotification(){
    window.Android.openNotification();
}

function openWhatsApp(){
    window.Android.openWhatsApp();
}