import React from 'react'
import BottomBar from '../component/BottomBar'
import Carousel from '../page-study/Carousal'
import Option from '../page-study/Options'

export default function Study() {
  return (
    <div>
        
        <Carousel />

        <Option/>

        <BottomBar selectStudy="true"/>
    </div>
  )
}
